import React, { Component } from "react";
import SingleContact from "./singleContact";
import { TwilioService } from "../../../../utils/TwilioService";
import { Api } from "../../../../utils/Api";
import $ from "jquery";

class AllContacts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contacts: [],
      invites: [],
      channelId: "",
      chatToken: this.props.chatToken,
      lastMessage: "",
      previousContact: 0,
      filteredContacts: [],
      activeChannel: null,
      selectedChannel: "",
      keyword: ""
    };

    setInterval(async () => {
      if (this.state.invites.length > 0) await this.getInvitations();
    }, 5000);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.chatToken !== this.props.chatToken) {
      this.setState({
        chatToken: this.props.chatToken,
      });
    }
  }

  getInvitations = async () => {
    const response = await Api(
      "get",
      `chat/get-invitations/${JSON.parse(localStorage.getItem("user"))._id}`,
      null
    );
    if (response.status === 200) {
      this.setState({ invites: response.data.data });
    }
  };

  getChannels = async () => {
    await TwilioService.getInstance()
      .getChatClient(localStorage.getItem("chatToken"))
      .then(async (client) => {
        await client.getSubscribedChannels().then(async (paginator) => {
          for (let i = 0; i < paginator.items.length; i++) {
            const channel = paginator.items[i];
            channel.numberOfMessages = channel.lastConsumedMessageIndex
              ? channel.lastMessage.index - channel.lastConsumedMessageIndex
              : 0;
            if (channel.status === "joined") this.state.contacts.push(channel);
            channel.on("messageAdded", async (message) => {
              let latest = this.state.contacts.filter(
                (item) => item.uniqueName === message.channel.uniqueName
              );
              let previous = this.state.contacts.filter(
                (item) => item.uniqueName !== message.channel.uniqueName
              );
              if (
                message.author !==
                  JSON.parse(localStorage.getItem("user"))._id &&
                this.state.selectedChannel !== message.channel.uniqueName
              )
                latest[0].numberOfMessages += 1;
              previous.unshift(latest[0]);
              this.setState({ contacts: previous });
            });
          }
        });
        this.getInvitations();
        await client.on("channelInvited", (channel) => {
          this.state.invites.unshift(channel);
          this.setState({ invites: this.state.invites });
        });
      })
      .catch(async (err) => {
        if (err.message === "Twilio token is null or undefined") {
          const response = await Api(
            "get",
            `chat/generate-token/${
              JSON.parse(localStorage.getItem("user"))._id
            }`,
            null
          );
          if (response.status === 200) {
            localStorage.setItem("chatToken", response.data.jwt);
            await this.getChannels();
          }
        }
      });

    this.state.contacts.sort(function (a, b) {
      if (
        a.uniqueName !== JSON.parse(localStorage.getItem("user"))._id &&
        b.uniqueName !== JSON.parse(localStorage.getItem("user"))._id
      ) {
        let keyA = new Date(a.lastMessage.dateCreated),
          keyB = new Date(b.lastMessage.dateCreated);
        if (keyB < keyA) return -1;
        if (keyB > keyA) return 1;
        return 0;
      }
    });

    this.setState({ contacts: this.state.contacts });
  };

  async componentDidMount() {
    await this.getChannels();
  }

  joinChannel = async (channelName, index, invite) => {
    this.setState({ selectedChannel: channelName });
    if (!invite) {
      await TwilioService.getInstance()
        .getChatClient(localStorage.getItem("chatToken"))
        .then(
          async (client) =>
            await client
              .getChannelByUniqueName(
                this.state.contacts[this.state.previousContact].uniqueName
              )
              .then((channel) =>
                channel.channelState.status !== "joined"
                  ? channel.join()
                  : channel
              )
        )
        .then(async (channel) => {
          channel.removeAllListeners();
        })
        .catch(async (err) => {
          if (err.message === "Twilio token is null or undefined") {
            const response = await Api(
              "get",
              `chat/generate-token/${
                JSON.parse(localStorage.getItem("user"))._id
              }`,
              null
            );
            if (response.status === 200) {
              localStorage.setItem("chatToken", response.data.jwt);
              await this.joinChannel(channelName, index, invite);
            }
          }
        });
    }

    TwilioService.getInstance()
      .getChatClient(localStorage.getItem("chatToken"))
      .then((client) =>
        client
          .getChannelByUniqueName(channelName)
          .then((channel) =>
            channel.channelState.status !== "joined" ? channel.join() : channel
          )
      )
      .then(async (channel) => {
        this.props.channelId(channel.sid);
        channel
          .getMembers()
          .then((members) => this.props.channelMembers(members));
        await channel.updateLastConsumedMessageIndex(channel.lastMessage.index);
        if (!invite) {
          let contacts = this.state.contacts.filter(
            (item) => item.uniqueName === channelName
          );
          contacts[0].numberOfMessages = 0;
          this.setState({
            contacts: this.state.contacts,
            previousContact: index,
          });
        }
        if (invite) {
          this.state.contacts.unshift(this.state.invites[index]);
          this.state.invites.splice(index, 1);
          this.setState({
            contacts: this.state.contacts,
            invites: this.state.invites,
          });
        }
        if ($(window).width() <= 735) {
          $("#sidepanel").hide();
          $(".open-sidebar").show();
        }
        await Api(
          "get",
          `chat/remove-invitation/${channel.sid}/${channelName}/${
            JSON.parse(localStorage.getItem("user"))._id
          }`,
          null
        );
      })
      .catch(async (err) => {
        if (err.message === "Twilio token is null or undefined") {
          const response = await Api(
            "get",
            `chat/generate-token/${
              JSON.parse(localStorage.getItem("user"))._id
            }`,
            null
          );
          if (response.status === 200) {
            localStorage.setItem("chatToken", response.data.jwt);
            await this.joinChannel(channelName, index, invite);
          }
        }
      });
  };

  search = (keyword) => {
    this.setState({ keyword: keyword });
    const filtered = this.state.contacts.filter((contact) => {
      return contact.friendlyName.toLowerCase().includes(keyword.toLowerCase());
    });
    this.setState({ filteredContacts: filtered });
  };

  render() {
    return (
      <React.Fragment>
        <div className="row" id={"search"}>
          <div className="col-md-12">
            <div
              className="form-group"
              style={{ background: "#4444444f", padding: "0 14px" }}
            >
              <i className="fa fa-search"> </i>
              <input
                type="text"
                className={"form-control d-inline-block ml-2 search"}
                value={this.state.keyword}
                style={{
                  width: "92%",
                  background: "transparent",
                  border: "none",
                  outline: "none",
                  color: "white",
                }}
                placeholder={"Search"}
                onChange={(e) => this.search(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div id="contacts" style={{ borderTop: "2px solid white" }}>
          <ul>
            {this.state.invites.map((invite, index) =>
              invite.uniqueName !==
              JSON.parse(localStorage.getItem("user"))._id ? (
                <SingleContact
                  key={index}
                  active={invite.uniqueName === this.state.activeChannel}
                  invite={true}
                  uniqueName={invite.uniqueName}
                  name={invite.friendlyName}
                  onClick={() => {
                    this.joinChannel(invite.uniqueName, index, true);
                    this.setState({ activeChannel: invite.uniqueName });
                    if($(window).width() <= 735) {
                      $('#sidepanel').hide()
                      $('.content').show()
                      $('.open-sidebar').show()
                    }
                  }}
                />
              ) : null
            )}
            {this.state.filteredContacts.length > 0
              ? this.state.filteredContacts.map((contact, index) =>
                  contact.uniqueName !==
                  JSON.parse(localStorage.getItem("user"))._id ? (
                    <SingleContact
                      key={index}
                      active={contact.uniqueName === this.state.activeChannel}
                      invite={false}
                      lastMessage={contact.contactLastMessage}
                      uniqueName={contact.uniqueName}
                      name={contact.friendlyName}
                      numberOfMessages={contact.numberOfMessages}
                      onClick={() => {
                        this.joinChannel(contact.uniqueName, index, false);
                        this.setState({
                          filteredContacts: [],
                          activeChannel: contact.uniqueName,
                          keyword: "",
                        });
                        if($(window).width() <= 735) {
                          $('#sidepanel').hide()
                          $('.content').show()
                          $('.open-sidebar').show()
                        }
                      }}
                    />
                  ) : null
                )
              : this.state.contacts.map((contact, index) =>
                  contact.uniqueName !==
                  JSON.parse(localStorage.getItem("user"))._id ? (
                    <SingleContact
                      key={index}
                      active={contact.uniqueName === this.state.activeChannel}
                      invite={false}
                      lastMessage={contact.contactLastMessage}
                      uniqueName={contact.uniqueName}
                      name={contact.friendlyName}
                      numberOfMessages={contact.numberOfMessages}
                      onClick={() => {
                        this.joinChannel(contact.uniqueName, index, false);
                        this.setState({
                          filteredContacts: [],
                          activeChannel: contact.uniqueName,
                          keyword: "",
                        });
                        if($(window).width() <= 735) {
                          $('#sidepanel').hide()
                          $('.content').show()
                          $('.open-sidebar').show()
                        }
                      }}
                    />
                  ) : null
                )}
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

export default AllContacts;
