import React, {Component} from 'react';
import '../../assets/css/auth.css';
import logo from '../../assets/img/logo.jpg';
import $ from 'jquery';
import DatePicker from 'react-date-picker';
import Loader from "../../components/Loader";
import Toast from "../../components/Toast";
import {Api} from "../../utils/Api";
import {Link, Redirect} from "react-router-dom";

class AuthView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      name: '',
      dob: '',
      gender: '',
      email: '',
      password: '',
      read: false,
      loading: false,
      show: false,
      title: '',
      msg: '',
      redirect: false
    }
  }

  showLoader = () => {
    this.setState({
      loading: true
    });
  }

  hideLoader = () => {
    this.setState({
      loading: false
    });
  }

  switchTab = () => {
    $(".form-signin").toggleClass("form-signin-left");
    $(".form-signup").toggleClass("form-signup-left");
    $(".signup-active").toggleClass("signup-inactive");
    $(".signin-inactive").toggleClass("signin-active");
    $(".forgot").toggleClass("forgot-left");
    $(this).removeClass("idle").addClass("inactive");
  };

  signup = async (e) => {
    e.preventDefault();

    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(this.state.name.length === 0 || this.state.dob === "" || this.state.gender === "" || this.state.email.length === 0 || this.state.password.length < 8) {
      this.setState({
        show: true,
        title: "Error",
        msg: "Name, dob, gender, email & password is required. Password minimum length is 8 characters."
      })
    } else {
      if(re.test(this.state.email)) {
        if (this.state.read) {
          this.showLoader()
          const formData = new FormData();
          formData.append("name", this.state.name);
          formData.append("dob", this.state.dob);
          formData.append("gender", this.state.gender);
          formData.append("email", this.state.email);
          formData.append("password", this.state.password);
          const response = await Api('post', `auth/register`, formData);

          if (response.status === 201) {
            this.setState({
              show: true,
              title: 'Success',
              msg: response.data.msg,
              loading: false
            })
            this.switchTab()
          } else {
            this.setState({
              show: true,
              title: 'Error',
              msg: response.data.msg,
              loading: false
            })
          }
        } else {
          this.setState({
            show: true,
            title: "Error",
            msg: "Please click the checkbox to accept terms & conditions"
          })
        }
      } else {
        this.setState({
          show: true,
          title: "Error",
          msg: "Enter a valid email",
          loading: false
        })
      }
    }
  }

  login = async (e) => {
    e.preventDefault();

    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(this.state.username === 0 || this.state.password.length < 8) {
      this.setState({
        show: true,
        title: "Error",
        msg: "Username & password is required. Password minimum length is 8 characters."
      })
    } else {
      if(re.test(this.state.username)) {
        this.showLoader()
        const formData = new FormData();
        formData.append("username", this.state.username);
        formData.append("password", this.state.password);
        const response = await Api('post', `auth/login`, formData);

        if (response.status === 200) {
          await localStorage.setItem('teqdr-auth-token', response.data.token);
          await localStorage.setItem('user', JSON.stringify(response.data.user));
          this.setState({
            show: true,
            title: 'Success',
            msg: response.data.msg,
            loading: false,
            redirect: true
          })
        } else {
          this.setState({
            show: true,
            title: 'Error',
            msg: response.data.msg,
            loading: false
          })
        }
      } else {
        this.setState({
          show: true,
          title: "Error",
          msg: "Enter a valid email",
          loading: false
        })
      }
    }
  }

  componentDidMount() {
    $('body').css('overflow-y', 'scroll');
  }

  render() {
    if(this.state.redirect) return <Redirect to={"/chat-board"}/>
    return (
      <React.Fragment>
        {this.state.loading? <Loader/>:null}
        <Toast title={this.state.title} msg={this.state.msg} show={this.state.show} close={(s)=>this.setState({show: s})} />
        <div className="container pt-0 pt-md-4 px-0 px-md-3">
          <div id="chat-board" style={{padding: "20px 0", background: 'white'}}>
            <div className="frame pt-1 pt-md-4">
              <div id="logo" className={"text-center"}>
                <img src={logo} style={{width: 300, height: 120}} alt="logo not found"/>
              </div>
              <div>
                <ul className="links p-0 text-center mt-2">
                  <li className="signin-inactive li"><button className="btn" onClick={()=>this.switchTab()}>LOGIN</button></li>
                  <li className="signup-active li"><button className="btn" onClick={()=>this.switchTab()}>REGISTER</button></li>
                </ul>
              </div>
              <div>
                <form className="form-signin" onSubmit={(e)=>this.login(e)}>
                  <label htmlFor="username" className={"d-inline-block label"}>Email</label>
                  <input className={"d-inline-block input-field-style"} required={true} type="text" name="username" placeholder="Enter Email" onChange={(e)=>this.setState({username: e.target.value})}/>
                  <label htmlFor="password" className={"d-inline-block mt-3 label"}>Password</label>
                  <input className={"d-inline-block mt-3 input-field-style"} required={true} type="password" name="password" placeholder="********" onChange={(e)=>this.setState({password: e.target.value})}/>
                  <div className="text-center mt-5">
                    <button className="btn-signin btn btn-md" type={"submit"}>LOGIN</button>
                    <div className="forgot mt-5">
                      <Link to={'/forgot-password'}>Forgot your password?</Link>
                    </div>
                  </div>
                </form>

                <form className="form-signup" onSubmit={(e)=>this.signup(e)}>
                  <label htmlFor="name" className={"d-inline-block label"}>Name</label>
                  <input className={"d-inline-block input-field-style"} required={true} type="text" name="name" placeholder="Enter Name" onChange={(e)=>this.setState({name: e.target.value})}/>
                  <label htmlFor="dob" className={"d-inline-block mt-2 label"}>DOB</label>
                  <DatePicker
                    format="dd/MM/y"
                    value={this.state.dob}
                    className={"d-inline-block input-field-style dob-field mt-2"}
                    required={true}
                    onChange={(e)=>this.setState({dob: e})}
                  />
                  <label htmlFor="gender" className={"d-inline-block mt-2 label"}>Gender</label>
                  <select name="gender" required={true} className={"d-inline-block input-field-style gender-field mt-2"} onChange={(e)=>this.setState({gender: e.target.value})}>
                    <option value="">Select Your Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                  <label htmlFor="email" className={"d-inline-block mt-2 label"}>Email</label>
                  <input className={"d-inline-block input-field-style mt-2"} required={true} type="email" name="email" placeholder="email@example.com" onChange={(e)=>this.setState({email: e.target.value})}/>
                  <label htmlFor="password" className={"d-inline-block mt-2 label"}>Password</label>
                  <input className={"d-inline-block input-field-style mt-2"} required={true} min={8} max={16} type="password" name="password" placeholder="********" onChange={(e)=>this.setState({password: e.target.value})}/>
                  <div className="row">
                    <div className="col-1 col-sm-1 col-md-1">
                      <input type="checkbox" checked={this.state.read} onChange={(e)=>this.setState({read: !this.state.read})} style={{height: 20, width: 20, marginTop: 4}}/>
                    </div>
                    <div className="col-11 col-sm-11 col-md-11">
                      <p>Please ensure you have read our <a href="https://teqdr.com/privacy-policy/" target={"_blank"}>T&C</a> which highlight our Acceptable Use Policy and Privacy Policy. By registering and using this service you agree to abide by the terms and conditions of our Acceptable Use Policy and Privacy Policy.</p>
                    </div>
                  </div>
                  <div className="text-center mt-3">
                    <button className="btn-signup btn btn-md" type={"submit"}>Register</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AuthView;
