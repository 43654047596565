import React, {Component} from 'react';

class Modal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: this.props.show,
      title: this.props.title,
      msg: this.props.msg
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.show !== this.props.show) {
      this.setState({
        show: this.props.show,
        title: this.props.title,
        msg: this.props.msg
      });
    }
  }

  closeModal = () => {
    this.setState({show: false})
    this.props.close(false)
  }

  render() {
    return (
      <React.Fragment>
        {(this.state.show)? (
          <div className="modal d-block" tabIndex="-1" role="dialog" style={{background: 'rgb(0 0 0 / 50%)'}}>
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">{this.state.title}</h5>
                  <button type="button" className="close" onClick={()=>this.closeModal()}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p>{this.state.msg}</p>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-success" onClick={this.props.onClick}>Yes</button>
                  <button type="button" className="btn btn-danger" onClick={()=>this.closeModal()}>Cancel</button>
                </div>
              </div>
            </div>
          </div>
        ):null}
      </React.Fragment>
    );
  }
}

export default Modal;
