import React, {Component} from 'react';
import "../assets/css/chatview.css";
import '../assets/css/reset.min.css';
import $ from 'jquery';
import {TwilioService} from "../utils/TwilioService";
import {Link} from "react-router-dom";
import {Api} from "../utils/Api";
import Loader from "../components/Loader";
import Profile from "./chat/sidebar/profile";
import AllContacts from "./chat/sidebar/contacts/allContacts";
import ContactProfile from "./chat/conversation/contactProfile";
import Messages from "./chat/conversation/messages";
import WriteMessage from "./chat/conversation/writeMessage";

class ChatBoard extends Component {
  constructor(props) {
    super(props);

    this.chatClientChannel = React.createRef();
    this.chatMessagesPaginator = React.createRef();

    this.state = {
      channelName: JSON.parse(localStorage.getItem('user'))._id,
      chatToken: '',
      userRole: JSON.parse(localStorage.getItem('user')).role,
      selectedChannelId: '',
      loading: false,
      paginator: null,
      loggedIn: true,
      doctors: [],
      messages: [],
      members: []
    }
  }

  showLoader = () => {
    this.setState({
      loading: true
    });
  }

  async componentDidMount() {
    window.scrollTo(0,40);

    $("#profile-img").click(function() {
      $("#status-options").toggleClass("active");
    });

    $(".expand-button").click(function() {
      $("#profile").toggleClass("expanded");
      $("#contacts").toggleClass("expanded");
    });

    $("#status-options ul li").click(function() {
      $("#profile-img").removeClass();
      $("#status-online").removeClass("active");
      $("#status-away").removeClass("active");
      $("#status-busy").removeClass("active");
      $("#status-offline").removeClass("active");
      $(this).addClass("active");

      if($("#status-online").hasClass("active")) {
        $("#profile-img").addClass("online");
      } else if ($("#status-away").hasClass("active")) {
        $("#profile-img").addClass("away");
      } else if ($("#status-busy").hasClass("active")) {
        $("#profile-img").addClass("busy");
      } else if ($("#status-offline").hasClass("active")) {
        $("#profile-img").addClass("offline");
      } else {
        $("#profile-img").removeClass();
      };

      $("#status-options").removeClass("active");
    });

    $(document).ready(function () {
      if($(window).width() <= 735) {
        if ($('#sidepanel').is(':visible')) {
          $('.open-sidebar').hide()
          $('.content').hide()
        } else {
          $('.open-sidebar').show()
          $('.content').show()
        }
      }
    })
    await this.getToken()
  }

  getToken = async () => {
    this.showLoader()
    const response = await Api('get', `chat/generate-token/${this.state.channelName}`, null);

    if (response.status === 200) {
      localStorage.setItem("chatToken", response.data.jwt)
      this.setState({
        chatToken: response.data.jwt,
        doctors: response.data.doctors
      })
      await this.createOrJoinChannel()
    } else {
      this.setState({
        show: true,
        title: 'Error',
        msg: response.data.msg,
        loading: false
      })
    }
  }

  createOrJoinChannel = async () => {
    TwilioService.getInstance()
      .getChatClient(this.state.chatToken)
      .then((client) =>
        client
          .getChannelByUniqueName(this.state.channelName)
          .then((channel) => (channel.channelState.status !== 'joined' ? channel.join() : channel))
          .catch(() =>
            client.createChannel({ uniqueName: this.state.channelName, friendlyName: JSON.parse(localStorage.getItem('user')).name }).then((channel) => {
              this.createOrJoinChannel()
            }),
          )
      )
      .then((channel) => {
        channel.getMembers().then(members=>(this.setState({members: members})))
        this.getAllMessages(channel.sid);
      })
      .catch((err) => {
        if(err.message === "Twilio token is null or undefined") this.getToken()
      })
  }

  setChannelEvents = async (channel) => {
    let objDiv = document.getElementsByClassName("messages")[0];
    this.chatClientChannel.current = channel;
    this.chatClientChannel.current.on('messageAdded', async (message) => {
      const { avatar } = message.attributes;
      message.avatar = avatar;
      const newMessage = TwilioService.getInstance().parseMessage(message);
      if(this.state.userRole === "patient" && this.state.messages.length === 0) {
        Api('get', `chat/send-invitations/${channel.sid}`, null);
      }
      await this.chatClientChannel.current.getMembers().then(members=> {
        if(members.length !== this.state.members.length) this.setState({members: members})
      })
      this.state.messages.push(newMessage)
      this.setState({ messages: this.state.messages })
      objDiv.scrollTop = objDiv.scrollHeight
    });
    return this.chatClientChannel.current;
  }

  getAllMessages = (channelId) => {
    this.setState({selectedChannelId: channelId});
    let objDiv = document.getElementsByClassName("messages")[0];
    TwilioService.getInstance()
      .getChatClient()
      .then((client) => client.getChannelBySid(channelId))
      .then((channel) => this.setChannelEvents(channel))
      .then((currentChannel) => currentChannel.getMessages(20))
      .then((paginator) => {
        this.chatMessagesPaginator.current = paginator;
        const newMessages = TwilioService.getInstance().parseMessages(paginator.items);
        this.setState({ messages: newMessages, loading: false, paginator: paginator })
        if(newMessages.length > 0) objDiv.scrollTop = objDiv.scrollHeight
      })
      .catch((err) => {
        this.setState({ loading: false })
        alert(err.message)
      })
  }

  loadPreviousMessages = () => {
    let objDiv = document.getElementsByClassName("messages")[0];
    let scrollValue = null;
    if($('.messages').scrollTop() + $('.messages').height() < $('.messages').height() + 10) {
      scrollValue = objDiv.scrollHeight
    }
    if(objDiv.scrollTop === 0) {
      if(this.state.paginator.hasPrevPage) {
        this.showLoader()
        this.state.paginator.prevPage().then(prev => {
          const newMessages = TwilioService.getInstance().parseMessages(prev.items);
          newMessages.forEach((newMessage, index) => {
            let num = newMessages.length - 1;
            this.state.messages.unshift(newMessages[num - index])
          })
          this.setState({messages: this.state.messages, loading: false, paginator: prev});
          $('.messages').scrollTop(objDiv.scrollHeight - scrollValue);
        });
      }
    }
  }

  openSidebar = () => {
    if($(window).width() <= 735) {
      $('#sidepanel').show()
      $('.content').hide()
      $('.open-sidebar').hide()
    }
  }

  logout = () => {
    this.showLoader()
    localStorage.removeItem("teqdr-auth-token");
    localStorage.removeItem("user");
    localStorage.removeItem("chatToken");
    this.setState({loggedIn: false, loading: false});
  };

  render() {
    if(!this.state.loggedIn) window.location.replace('/');
    return (
      <React.Fragment>
        {this.state.loading? <Loader/>:null}
        <div id="chat-board">
          <div id="frame">
            {(this.state.userRole === "doctor")? (
              <div id="sidepanel">
                <Profile/>
                <AllContacts chatToken={this.state.chatToken} messages={this.state.messages} channelMembers={(s)=>this.setState({members: s})} channelId={(s)=>this.getAllMessages(s)}/>
                <div id="bottom-bar">
                  <Link to={'/change-password'}>
                    <button id="settings"><i className="fa fa-cog fa-fw" aria-hidden="true"> </i> <span> Change Password</span></button>
                  </Link>
                  <button id="settings" onClick={()=>this.logout()}><i className="fa fa-sign-out fa-fw" aria-hidden="true"> </i> <span> Logout</span></button>
                </div>
              </div>
            ):null}
            <div className={(this.state.userRole === "patient")? ('patient content'):("content")}>
              <React.Fragment>
                <ContactProfile selectedChannelId={this.state.selectedChannelId} doctors={this.state.doctors} messages={this.state.messages} members={this.state.members}/>
                <Messages messages={this.state.messages} onScroll={()=>this.loadPreviousMessages()}/>
                {this.state.userRole === "doctor"? (
                  (this.state.messages.length > 0)? (
                    <WriteMessage chatClientChannel={this.chatClientChannel.current}/>
                  ):null
                ):(
                  <WriteMessage chatClientChannel={this.chatClientChannel.current}/>
                )}
                {(this.state.userRole === "doctor")? (
                  <i className="fa fa-chevron-right open-sidebar" onClick={()=>this.openSidebar()}> </i>
                ):null}
              </React.Fragment>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ChatBoard;
