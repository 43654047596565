import React, {Component, Fragment} from 'react';
import Avatar from "../../../../assets/img/avatar.jpg";
import {Api} from "../../../../utils/Api";
import {IMAGE_BASE_URL} from "../../../../utils/Url";
import {TwilioService} from "../../../../utils/TwilioService";
import moment from "moment";

class SingleContact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lastTextMessage: null,
      lastMediaMessage: null,
      uniqueName: null,
      numberOfMessages: 0,
      avatar: null,
      date: null,
      showProfile: false,
      patientName: "",
      gender: "",
      email: "",
      dob: ""
    }
  }

  viewPatientProfile = async (patientId) => {
    let response = await Api('get', `user/get-profile/${patientId}`, null);
    if (response.status === 200) this.setState({
      showProfile: true,
      patientName: response.data.data.name,
      gender: response.data.data.gender,
      email: response.data.data.email,
      dob: response.data.data.dob
    });
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.uniqueName !== this.state.uniqueName) {
      this.setState({
        uniqueName: this.props.uniqueName
      })
      let response = await Api('get', `user/get-profile/${this.props.uniqueName}`, null);
      if (response.status === 200) this.setState({avatar: response.data.data.profile_picture});
      this.getLastMessage(this.props.uniqueName)
    }

    if(prevProps.numberOfMessages !== this.state.numberOfMessages) {
      this.setState({
        numberOfMessages: this.props.numberOfMessages
      })
    }
  }

  getLastMessage = async (channelUniqueName) => {
    await TwilioService.getInstance()
      .getChatClient(localStorage.getItem("chatToken"))
      .then(async (client) =>
        await client
          .getChannelByUniqueName(channelUniqueName)
      )
      .then(async (channel) => {
        await channel.getMessages().then((messages) => {
          const totalMessages = messages.items.length;
          if (messages.items[totalMessages - 1] !== undefined) (messages.items[totalMessages - 1].media == null)? (this.setState({lastTextMessage: messages.items[totalMessages - 1].body, date: messages.items[totalMessages - 1].attributes.created_at})):(this.setState({lastMediaMessage: messages.items[totalMessages - 1].media, date: messages.items[totalMessages - 1].attributes.created_at}))
          this.setState({uniqueName: null})
        });
      })
  }

  async componentDidMount() {
    let response = await Api('get', `user/get-profile/${this.props.uniqueName}`, null);
    if (response.status === 200) this.setState({avatar: response.data.data.profile_picture});
    await this.getLastMessage(this.state.uniqueName)
  }

  render() {
    return (
      <React.Fragment>
        <li className={`contact ${(this.props.active)? ("active"):""}`} style={{height: 80, maxHeight: 80}}>
          {(this.props.invite)? (
            <p style={{backgroundColor: 'red', color: 'rgb(255, 255, 255)', width: 45, padding: '0px 7px', position: 'absolute', top: 25, right: 20, borderRadius: 25}}>New</p>
          ):(
            <Fragment>
              <p style={{backgroundColor: 'transparent', color: 'rgb(255, 255, 255)', width: 140, padding: '0px 7px', position: 'absolute', top: 15, right: 5, textAlign: 'right', fontSize: 13}}>{(this.state.date)? (moment(this.state.date).format('LT')):("")}</p>
              {(this.state.numberOfMessages > 0)? (
                <p style={{backgroundColor: '#222f74', color: 'rgb(255, 255, 255)', padding: '2px 10px', position: 'absolute', top: 40, right: 11, textAlign: 'center', fontSize: 13, borderRadius: 8}}>{this.state.numberOfMessages}</p>
              ):null}
            </Fragment>
          )}
          <div className="wrap">
            {(this.state.avatar !== null)? (
              <img src={IMAGE_BASE_URL+"/profilePictures/"+this.state.avatar} style={{marginTop: 6}} alt="not found" onClick={()=>this.viewPatientProfile(this.props.uniqueName)}/>
            ):(
              <img src={Avatar} style={{marginTop: 6}} alt="not found" onClick={()=>this.viewPatientProfile(this.props.uniqueName)}/>
            )}
            <div className="meta" onClick={this.props.onClick}>
              <p className="name text-capitalize">{this.props.name}</p>
              {(this.props.invite)? (
                <p className="preview">{"Waiting For Reply"}</p>
              ):(
                (this.state.lastTextMessage !== null)? (
                  <p className="preview">{this.state.lastTextMessage}</p>
                ):(
                  (this.state.lastMediaMessage !== null)? (
                    <p className="preview">{(this.state.lastMediaMessage.contentType === "image/png" || this.state.lastMediaMessage.contentType === "image/jpeg")? (<span><i className="fa fa-gallery"> </i> Image</span>):((this.state.lastMediaMessage.contentType === "application/pdf")? (<span><i className="fa fa-file-pdf-o"> </i> Pdf File</span>):(<span><i className="fa fa-file-word-o"> </i> Document</span>))}</p>
                  ):null
                )
              )}
            </div>
          </div>
        </li>

        {(this.state.showProfile)? (
          <div className="modal d-block" tabIndex="-1" role="dialog" style={{background: 'rgb(0 0 0 / 50%)', color: '#000', paddingTop: 60}}>
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">{this.state.patientName} Profile</h5>
                  <button type="button" className="close" onClick={()=>this.setState({showProfile: false})}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-3 col-md-2">
                      <p><span style={{fontWeight: 'bold'}}>DOB:</span></p>
                    </div>
                    <div className="col-9 col-md-10">
                      <p>{moment(this.state.dob).format('ll')}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-3 col-md-2">
                      <p><span style={{fontWeight: 'bold'}}>Gender:</span></p>
                    </div>
                    <div className="col-9 col-md-10">
                      <p className={'text-capitalize'}>{this.state.gender}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-3 col-md-2">
                      <p><span style={{fontWeight: 'bold'}}>Email:</span></p>
                    </div>
                    <div className="col-9 col-md-10">
                      <p style={{wordBreak: 'break-all'}}>{this.state.email}</p>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-danger" onClick={()=>this.setState({showProfile: false})}>Close</button>
                </div>
              </div>
            </div>
          </div>
        ):null}
      </React.Fragment>
    );
  }
}

export default SingleContact;
