import React, {Component} from 'react';
import {Api} from "../utils/Api";
import Profile from '../assets/img/profile.png';
import Avatar from '../assets/img/avatar.jpg';
import Loader from "../components/Loader";
import Toast from "../components/Toast";
import {IMAGE_BASE_URL} from "../utils/Url";
import DatePicker from "react-date-picker";
import moment from "moment";
import {Link} from "react-router-dom";

class UpdateProfile extends Component {
  constructor() {
    super();

    this.state = {
      name: '',
      gender: '',
      dob: '',
      username: '',
      profile_picture: null,
      preview: null,
      loading: false,
      show: false,
      title: '',
      msg: ''
    }
  }

  showLoader = () => {
    this.setState({
      loading: true
    });
  }

  getProfile = async () => {
    this.showLoader()
    const response = await Api('get', `user/profile`, null);

    if (response.status === 200) {
      this.setState({
        loading: false,
        name: response.data.data.name,
        email: response.data.data.email,
        dob: moment(response.data.data.dob).toDate(),
        gender: response.data.data.gender,
        profile_picture: response.data.data.profile_picture
      })
    } else {
      this.setState({
        show: true,
        title: 'Error',
        msg: response.data.msg,
        loading: false
      })
    }
  }

  updateProfile = async (e) => {
    e.preventDefault();

    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(this.state.name.length === 0 || this.state.dob === "" || this.state.gender === "" || this.state.email.length === 0) {
      this.setState({
        show: true,
        title: "Error",
        msg: "Name, dob, gender & email is required."
      })
    } else {
      if(re.test(this.state.email)) {
        this.showLoader()
        const formData = new FormData();
        formData.append("name", this.state.name);
        formData.append("email", this.state.email);
        formData.append("dob", this.state.dob);
        formData.append("gender", this.state.gender);
        formData.append("profile_picture", this.state.profile_picture);
        const response = await Api('put', `user/update-profile`, formData);

        if (response.status === 200) {
          this.setState({
            show: true,
            title: 'Success',
            msg: response.data.msg,
            loading: false,
            preview: null,
            name: response.data.data.name,
            email: response.data.data.email,
            dob: moment(response.data.data.dob).toDate(),
            gender: response.data.data.gender,
            profile_picture: response.data.data.profile_picture
          })
          await localStorage.setItem('user', JSON.stringify(response.data.data));

          const formData = new FormData();
          formData.append("friendlyName", response.data.data.name);
          await Api('post', `chat/update-friendlyName/${response.data.data._id}`, formData);
        } else {
          this.setState({
            show: true,
            title: 'Error',
            msg: response.data.msg,
            loading: false
          })
        }
      } else {
        this.setState({
          show: true,
          title: "Error",
          msg: "Enter a valid email",
          loading: false
        })
      }
    }
  }

  componentDidMount() {
    this.getProfile()
  }

  render() {
    return (
      <React.Fragment>
        {this.state.loading? <Loader/>:null}
        <Toast title={this.state.title} msg={this.state.msg} show={this.state.show} close={(s)=>this.setState({show: s})} />
        <div id="chat-board">
          <div id="frame" className={"updateProfile"} style={{padding: "20px 0", background: 'white'}}>
            <div className="container">
              <Link to={'/chat-board'} style={{position: 'absolute', fontSize: 25, cursor: 'pointer', textDecoration: 'none', color: '#3e5164', zIndex: 999999999}}>
                <i className="fa fa-arrow-left"> </i>
              </Link>
              <div className="row">
                <div className="col-12 col-sm-2 col-md-2 text-center" style={{verticalAlign: 'middle', margin: 'auto', marginRight: 0}}>
                  <div className={"d-inline-block"} style={{width: 70, height: 70}}>
                    <img className={"w-100"} src={Profile} alt="not found"/>
                  </div>
                </div>
                <div className="col-md-7 col-sm-7 text-center" style={{verticalAlign: 'middle', margin: 'auto', marginLeft: 0}}>
                  <div>
                    <h1 style={{fontSize: 30}}>Profile Setting</h1>
                  </div>
                </div>
              </div>

              <div className="row mt-2 mt-md-2">
                <div className="col-md-12 text-center">
                  <div className={"d-inline-block profileContainer"}>
                    <label htmlFor="profileImage" className={"cameraIcon"}>
                      <i className="fa fa-camera"> </i>
                    </label>
                    {(this.state.preview)? (
                      <a href={this.state.preview} target={"_blank"}><img className={"w-100"} style={{borderRadius: '50%', height: '100%'}} src={this.state.preview} alt="not found"/></a>
                      ):(
                      (this.state.profile_picture)? (
                        <a href={IMAGE_BASE_URL+"/profilePictures/"+this.state.profile_picture} target={"_blank"}><img className={"w-100"} style={{borderRadius: '50%', height: '100%'}} src={IMAGE_BASE_URL+"/profilePictures/"+this.state.profile_picture} alt="not found"/></a>
                      ):(
                        <img className={"w-100"} style={{borderRadius: '50%', height: '100%', border: '1px solid #bbbbbb'}} src={Avatar} alt="not found"/>
                      )
                    )}
                  </div>
                </div>
              </div>

              <div className="row mt-3 mt-md-3">
                <div className="col-md-12">
                  <form onSubmit={(e)=>this.updateProfile(e)}>
                    <input style={{display: "none"}} type="file" id="profileImage" onChange={(e)=>this.setState({profile_picture: e.target.files[0], preview: URL.createObjectURL(e.target.files[0])})} name="profileImage"/>
                    <label htmlFor="name" className={"d-inline-block label"}>Name</label>
                    <input className={"d-inline-block input-field-style"} required={true} value={this.state.name} style={{paddingBottom: 7}} type="text" name="name" placeholder="Enter Name" onChange={(e)=>this.setState({name: e.target.value})}/>
                    <label htmlFor="dob" className={"d-inline-block mt-2 label"}>DOB</label>
                    <DatePicker
                      format="dd/MM/y"
                      value={this.state.dob}
                      className={"d-inline-block input-field-style dob-field mt-2"}
                      required={true}
                      onChange={(e)=>this.setState({dob: e})}
                    />
                    <label htmlFor="gender" className={"d-inline-block mt-2 label"}>Gender</label>
                    <select name="gender" required={true} style={{paddingBottom: 8}} value={this.state.gender} className={"d-inline-block input-field-style gender-field mt-2"} onChange={(e)=>this.setState({gender: e.target.value})}>
                      <option value="">Select Your Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                    <label htmlFor="email" className={"d-inline-block mt-2 label"}>Email</label>
                    <input className={"d-inline-block input-field-style mt-2"} value={this.state.email} required={true} type="email" name="email" placeholder="email@example.com" onChange={(e)=>this.setState({email: e.target.value})}/>
                    <div className="text-center mt-3">
                      <button className="btn-signup btn btn-md" type={"submit"}>Update</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default UpdateProfile;
