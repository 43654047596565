import React, {Component} from 'react';
import Loader from "../../components/Loader";
import Toast from "../../components/Toast";
import {Link} from "react-router-dom";
import Profile from "../../assets/img/profile.png";
import {Api} from "../../utils/Api";

class ForgotPassword extends Component {
  constructor() {
    super();

    this.state = {
      email: ''
    }
  }

  showLoader = () => {
    this.setState({
      loading: true
    });
  }

  forgotPassword = async (e) => {
    e.preventDefault();

    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(this.state.email.length === 0) {
      this.setState({
        show: true,
        title: "Error",
        msg: "Old password, new password & confirm password is required. Minimum length of password is 8 characters."
      })
    } else {
      if(re.test(this.state.email)) {
        this.showLoader()
        const formData = new FormData();
        formData.append("email", this.state.email);
        const response = await Api('post', `auth/forgot-password`, formData);

        if (response.status === 200) {
          this.setState({
            loading: false,
            show: true,
            title: 'Success',
            msg: response.data.msg
          })
        } else {
          this.setState({
            show: true,
            title: 'Error',
            msg: response.data.msg,
            loading: false
          })
        }
      } else {
        this.setState({
          show: true,
          title: "Error",
          msg: "Enter a valid email",
          loading: false
        })
      }
    }
  }
  render() {
    return (
      <React.Fragment>
        {this.state.loading? <Loader/>:null}
        <Toast title={this.state.title} msg={this.state.msg} show={this.state.show} close={(s)=>this.setState({show: s})} />
        <div id="chat-board">
          <div id="frame" className={"updateProfile"} style={{padding: "20px 0", background: 'white'}}>
            <div className="container">
              <Link to={'/'} style={{position: 'absolute', fontSize: 25, cursor: 'pointer', textDecoration: 'none', color: '#3e5164', zIndex: 999999999}}>
                <i className="fa fa-arrow-left"> </i>
              </Link>
              <div className="row">
                <div className="col-12 col-sm-2 col-md-2 text-center" style={{verticalAlign: 'middle', margin: 'auto', marginRight: 0}}>
                  <div className={"d-inline-block"} style={{width: 70, height: 70}}>
                    <img className={"w-100"} src={Profile} alt="not found"/>
                  </div>
                </div>
                <div className="col-md-8 col-sm-8 text-center" style={{verticalAlign: 'middle', margin: 'auto', marginLeft: 0}}>
                  <div>
                    <h1 style={{fontSize: 30}}>Forgot Password</h1>
                  </div>
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-md-12">
                  <form onSubmit={(e)=>this.forgotPassword(e)}>
                    <label htmlFor="email" className={"d-inline-block label"}>Email</label>
                    <input className={"d-inline-block input-field-style"} required={true} value={this.state.email} style={{paddingBottom: 7}} type="email" name="email" placeholder="Type your valid email" onChange={(e)=>this.setState({email: e.target.value})}/>
                    <div className="text-center mt-3">
                      <button className="btn-signup btn btn-md" type={"submit"}>Confirm</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ForgotPassword;
