import React, {Component} from 'react';
import Loader from "../../components/Loader";
import Toast from "../../components/Toast";
import {Link} from "react-router-dom";
import Profile from "../../assets/img/profile.png";
import {Api} from "../../utils/Api";

class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      loading: false,
      show: false,
      title: '',
      msg: '',
      loggedIn: true,
    }
  }

  showLoader = () => {
    this.setState({
      loading: true
    });
  }

  updatePassword = async (e) => {
    e.preventDefault();

    if(this.state.oldPassword.length === 0 || this.state.newPassword.length === 0 || this.state.confirmNewPassword === 0 || this.state.newPassword.length < 8) {
      this.setState({
        show: true,
        title: "Error",
        msg: "Old password, new password & confirm password is required. Minimum length of password is 8 characters."
      })
    } else {
      if(this.state.newPassword === this.state.confirmNewPassword) {
        this.showLoader()
        const formData = new FormData();
        formData.append("oldPassword", this.state.oldPassword);
        formData.append("newPassword", this.state.newPassword);
        const response = await Api('put', `user/change-password`, formData);

        if (response.status === 200) {
          localStorage.removeItem("teqdr-auth-token");
          localStorage.removeItem("user");
          localStorage.removeItem("chatToken");
          this.setState({
            loading: false,
            loggedIn: false
          })
        } else {
          this.setState({
            show: true,
            title: 'Error',
            msg: response.data.msg,
            loading: false
          })
        }
      } else {
        this.setState({
          show: true,
          title: "Error",
          msg: "New password and confirm password should be same"
        })
      }
    }
  }

  render() {
    if(!this.state.loggedIn) window.location.replace('/');
    return (
      <React.Fragment>
        {this.state.loading? <Loader/>:null}
        <Toast title={this.state.title} msg={this.state.msg} show={this.state.show} close={(s)=>this.setState({show: s})} />
        <div id="chat-board">
          <div id="frame" className={"updateProfile"} style={{padding: "20px 0", background: 'white'}}>
            <div className="container">
              <Link to={'/chat-board'} style={{position: 'absolute', fontSize: 25, cursor: 'pointer', textDecoration: 'none', color: '#3e5164', zIndex: 99999999}}>
                <i className="fa fa-arrow-left"> </i>
              </Link>
              <div className="row">
                <div className="col-12 col-sm-2 col-md-2 text-center" style={{verticalAlign: 'middle', margin: 'auto', marginRight: 0}}>
                  <div className={"d-inline-block"} style={{width: 70, height: 70}}>
                    <img className={"w-100"} src={Profile} alt="not found"/>
                  </div>
                </div>
                <div className="col-md-8 col-sm-8 text-center" style={{verticalAlign: 'middle', margin: 'auto', marginLeft: 0}}>
                  <div>
                    <h1 style={{fontSize: 30}}>Change Password</h1>
                  </div>
                </div>
              </div>

              <div className="row mt-5" id={"changePasswordContainer"}>
                <div className="col-md-12">
                  <form onSubmit={(e)=>this.updatePassword(e)}>
                    <label htmlFor="oldPassword" className={"d-inline-block label"} style={{width: '36%', fontSize: 12}}>Old Password</label>
                    <input className={"d-inline-block input-field-style"} required={true} value={this.state.oldPassword} style={{paddingBottom: 7, width: '64%', fontSize: 12}} type="password" name="oldPassword" placeholder="Type your old password" onChange={(e)=>this.setState({oldPassword: e.target.value})}/>
                    <label htmlFor="newPassword" className={"d-inline-block label mt-3"} style={{width: '36%', fontSize: 12}}>New Password</label>
                    <input className={"d-inline-block input-field-style"} required={true} value={this.state.newPassword} style={{paddingBottom: 7, width: '64%', fontSize: 12}} type="password" name="newPassword" placeholder="Type your new password" onChange={(e)=>this.setState({newPassword: e.target.value})}/>
                    <label htmlFor="confirmNewPassword" className={"d-inline-block label mt-3"} style={{width: '36%', fontSize: 12}}>Confirm Password</label>
                    <input className={"d-inline-block input-field-style"} required={true} value={this.state.confirmNewPassword} style={{paddingBottom: 7, width: '64%', fontSize: 12}} type="password" name="confirmNewPassword" placeholder="Type new password again" onChange={(e)=>this.setState({confirmNewPassword: e.target.value})}/>
                    <div className="text-center mt-3">
                      <button className="btn-signup btn btn-md" type={"submit"}>Update</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ChangePassword;
