import React, {Component} from 'react';

class Toast extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: this.props.show,
      title: this.props.title,
      msg: this.props.msg
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.show !== this.props.show) {
      this.setState({
        show: this.props.show,
        title: this.props.title,
        msg: this.props.msg
      });
      setTimeout(this.closeToast,5000);
    }
  }

  closeToast = () => {
    this.setState({show: false})
    this.props.close(false)
  }

  render() {
    return (
      <React.Fragment>
        {(this.state.show)? (
          <div className="toast" role="alert" aria-live="assertive" aria-atomic="true" style={{position: 'fixed', right: 30, width: 300, top: 20, opacity: 1, zIndex: 999999999999999}}>
            <div className="toast-header" style={{backgroundColor: (this.props.title === "Success")? ("#2eb85c"):("red"), color: 'white', fontWeight: 'bold'}}>
              <strong className="mr-auto">{this.props.title}</strong>
              <button type="button" className="ml-2 mb-1 close" onClick={()=>this.closeToast()}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="toast-body text-capitalize" style={{backgroundColor: (this.props.title === "Success")? ("rgba(88, 185, 93, 0.4)"):("rgba(255,0,0,0.4)"), color: 'black'}}>
              {this.props.msg}
            </div>
          </div>
        ):null}
      </React.Fragment>
    );
  }
}

export default Toast;
