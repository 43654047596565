import React, {Component} from 'react';
import {BrowserRouter} from 'react-router-dom';
import PublicRoute from "./utils/PublicRoute";
import PrivateRoute from "./utils/PrivateRoute";
import AuthView from "./views/auth/authView";
import ChatBoard from "./views/ChatBoard";
import UpdateProfile from "./views/updateProfile";
import ChangePassword from "./views/auth/changePassword";
import ForgotPassword from "./views/auth/forgotPassword";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <PublicRoute path="/" name="Auth View" component={AuthView} />
        <PrivateRoute path="/chat-board" name="Chat Board" component={ChatBoard} />
        <PrivateRoute path="/profile-setting" name="Profile Setting" component={UpdateProfile} />
        <PrivateRoute path="/change-password" name="Change Password" component={ChangePassword} />
        <PublicRoute path="/forgot-password" name="Forgot Password" component={ForgotPassword} />
      </BrowserRouter>
    );
  }
}

export default App;
