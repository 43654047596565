import React, {Component} from 'react';
import SentMessage from "./sentMessage";
import ReceivedMessage from "./receivedMessage";
import {Api} from "../../../utils/Api";
import Modal from "../../../components/Modal";

class Messages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      messages: this.props.messages,
      messageId: 0,
      channelId: 0,
      show: false,
      title: '',
      msg: ''
    }
  }

  deleteMessage = async () => {
    const response = await Api('get', `chat/delete-message/${this.state.channelId}/${this.state.messageId}`, null);

    if (response.status === 200) {
      this.setState({
        messages: this.state.messages.filter(message=> message.sid !== this.state.messageId),
        messageId: 0,
        channelId: 0,
        show: false,
        title: '',
        msg: ''
      })
    } else {
      this.setState({
        show: true,
        title: 'Error',
        msg: "Something Went Wrong"
      })
    }
  }

  handleMouseDown = async (messageId, channelId) => {
    if(JSON.parse(localStorage.getItem('user')).role === "doctor") {
      this.setState({
        show: true,
        title: "Confirmation",
        msg: 'Are You Sure To Delete This Message? This Action Cannot Be Undone.',
        messageId: messageId,
        channelId: channelId
      })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.messages !== this.props.messages) {
      this.setState({
        messages: this.props.messages
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <Modal title={this.state.title} msg={this.state.msg} show={this.state.show} onClick={()=>this.deleteMessage()} close={(s)=>this.setState({show: s})}  />
        <div className="messages" style={{background: '#fff'}} id={"messages"} onScroll={this.props.onScroll}>
          <ul>
            {this.state.messages.map((message,index)=>(
              (message.user.name === JSON.parse(localStorage.getItem('user'))._id)? (
                <SentMessage message={message} key={index} onDoubleClick={()=>this.handleMouseDown(message.sid, message.channelId)}/>
              ):(
                <ReceivedMessage message={message} key={index} onDoubleClick={()=>this.handleMouseDown(message.sid, message.channelId)}/>
              )
            ))}
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

export default Messages;
