import React, {Component} from 'react';
import Loader from "../../../components/Loader";
import Profile from "../../../assets/img/profile.png";
import {Link} from "react-router-dom";
import {Api} from "../../../utils/Api";
import {IMAGE_BASE_URL} from "../../../utils/Url";
import Avatar from "../../../assets/img/avatar.jpg";
import {TwilioService} from "../../../utils/TwilioService";
import moment from "moment";

class ContactProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      members: this.props.members,
      loggedIn: true,
      loading: false,
      showList: false,
      showDoctorsList: false,
      doctors: this.props.doctors,
      selectedChannelId: this.props.selectedChannelId,
      channelMembers: this.props.channelMembers,
      chatToken: this.props.chatToken,
      messages: this.props.messages,
      showProfile: false,
      patientName: "",
      gender: "",
      email: "",
      dob: ""
    }
  }

  showLoader = () => {
    this.setState({
      loading: true
    });
  }

  viewPatientProfile = async (patientId) => {
    let response = await Api('get', `user/get-profile/${patientId}`, null);
    if (response.status === 200) this.setState({
      showProfile: true,
      patientName: response.data.data.name,
      gender: response.data.data.gender,
      email: response.data.data.email,
      dob: response.data.data.dob
    });
  }

  logout = () => {
    this.showLoader()
    localStorage.removeItem("teqdr-auth-token");
    localStorage.removeItem("user");
    localStorage.removeItem("chatToken");
    this.setState({loggedIn: false, loading: false});
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.chatToken !== this.props.chatToken) {
      this.setState({
        chatToken: this.props.chatToken
      });
    }

    if (prevProps.members !== this.props.members) {
      this.setState({
        members: this.props.members
      });
      this.getAvatars(this.props.members)
    }

    if (prevProps.messages !== this.props.messages) {
      this.setState({
        messages: this.props.messages
      });
    }

    if (prevProps.doctors !== this.props.doctors) {
      this.setState({
        doctors: this.props.doctors
      });
    }

    if (prevProps.selectedChannelId !== this.props.selectedChannelId) {
      this.setState({
        selectedChannelId: this.props.selectedChannelId
      });
    }
  }

  addMember = async (doctorId) => {
    await Api('get', `chat/add-member-to-channel/${this.state.selectedChannelId}/${doctorId}`, null);
    this.setState({showDoctorsList: false, doctors: this.state.doctors.filter((doctor=>{
      return doctor._id !== doctorId
    }))})
    await TwilioService.getInstance()
      .getChatClient(this.state.chatToken)
      .then(async (client) =>
        await client
          .getChannelByUniqueName(this.state.selectedChannelId)
          .then((channel) => (channel.channelState.status !== 'joined' ? channel.join() : channel))
      )
      .then(async (channel) => {
        await channel.getMembers().then(members=>{
          this.setState({members: members})
          this.getAvatars(members)
        })
      })
      .catch((err) => console.log( {message: err.message, type: 'danger' }))
  }

  getAvatars = (members) => {
    members.map(async (member, index)=> {
      let response = await Api('get', `user/get-profile/${member.identity}`, null);
      if (response.status === 200) member.avatar = response.data.data.profile_picture;
    })
  }

  async componentDidMount() {
    this.getAvatars(this.state.members)
  }

  render() {
    if(!this.state.loggedIn) window.location.replace('/');
    return (
      <React.Fragment>
        {this.state.loading? <Loader/>:null}
        <div className="contact-profile" style={{borderBottom: '2px solid #2d9f86'}}>
          <div className="row">
            {(this.state.members.length > 1)? (
              <React.Fragment>
                <div>
                  {this.state.members.map((member, index)=>(
                    <React.Fragment key={index}>
                      {(member.identity !== JSON.parse(localStorage.getItem('user'))._id)? (
                        (member.avatar !== null && member.avatar !== undefined)? (
                          <img src={IMAGE_BASE_URL+"/profilePictures/"+member.avatar} style={{marginTop: 6, position: 'absolute', left: `${index}0px`}} alt="not found"/>
                        ):(
                          <img src={Avatar} style={{marginTop: 6, position: 'absolute', left: `${index}0px`}} alt="not found"/>
                        )
                      ):null}
                    </React.Fragment>
                  ))}
                </div>
                <div className={"col-6 col-md-9 pl-0 contacts-info"} style={{marginLeft: `${parseInt("60")+parseInt(""+this.state.members.length+0)}px`}}>
                  {(JSON.parse(localStorage.getItem('user')).role === "doctor")? (
                    <React.Fragment>
                      <p className={"text-capitalize full-screen-channel"}>{this.state.members[0].channel.friendlyName}{(this.state.members.length > 2)? (', '):null}</p>
                      <p className={"text-capitalize small-screen-channel"} onClick={()=>this.viewPatientProfile(this.state.members[0].channel.uniqueName)}>{this.state.members[0].channel.friendlyName}{(this.state.members.length > 2)? (', '):null}</p>
                    </React.Fragment>
                  ):null}

                  {this.state.members.map((member, index)=>(
                    <React.Fragment key={index}>
                      {(member.identity !== JSON.parse(localStorage.getItem('user'))._id && member.channel.uniqueName !== member.identity)? (
                        <React.Fragment>
                        <p className={"text-capitalize full-screen-members"}>
                          {this.state.doctors.map(doctor=> (
                            (doctor._id.toString() === member.identity)? (
                              doctor.name
                            ):null
                          ))}
                          {(this.state.members.length > 2 && index !== this.state.members.length-1)? (', '):null}
                        </p>
                        <p className={"text-capitalize small-screen-members"}>
                          {this.state.doctors.map(doctor=> (
                            (doctor._id.toString() === member.identity)? (
                              doctor.name
                            ):null
                          ))}
                          {(this.state.members.length > 2 && index !== this.state.members.length-1)? (', '):null}
                        </p>
                        </React.Fragment>
                      ):null}
                    </React.Fragment>
                  ))}
                </div>
              </React.Fragment>
            ):(
              <React.Fragment>
                <div className={"col-2 col-sm-1 col-md-1 pr-0"}>
                  {(this.state.messages.length > 0)? (
                    <img src={Profile} alt="not found"/>
                  ):null}
                </div>
                <div className={"col-9 col-md-9 pl-3 pl-md-0"}>
                  {(this.state.messages.length > 0)? (
                    <p className={"text-capitalize"}>Waiting For Doctor To Connect</p>
                  ):null}
                </div>
              </React.Fragment>
            )}
            {(JSON.parse(localStorage.getItem('user')).role === "patient" && this.state.members.length > 0)? (
              <div className="col-1 col-md-1 social-media" style={{marginLeft: 70}} onClick={()=>this.setState({showList: !this.state.showList})}>
                <i className="fa fa-ellipsis-v fa-2x" style={{verticalAlign: 'middle'}}> </i>
              </div>
            ):null}
            {(JSON.parse(localStorage.getItem('user')).role === "doctor" && this.state.members.length > 1)? (
              <div className="col-1 col-md-1 social-media" onClick={()=>this.setState({showDoctorsList: !this.state.showDoctorsList})}>
                <i className="fa fa-plus" style={{verticalAlign: 'middle', fontSize: 22}}> </i>
              </div>
            ):null}
          </div>
          {(this.state.showList)? (
            <div style={{position: 'absolute', width: 220, right: 15, top: 50}}>
              <ul className={"list-group"}>
                <Link to={'/profile-setting'} style={{textDecoration: 'none', color: '#32465a'}}>
                  <li className={"list-group-item"} style={{height: 50}}>
                    <p style={{lineHeight: '28px'}}><i className="fa fa-user" style={{fontSize: 20, marginRight: 10}}> </i> Profile Settings</p>
                  </li>
                </Link>
                <Link to={'/change-password'} style={{textDecoration: 'none', color: '#32465a'}}>
                  <li className={"list-group-item"} style={{height: 50}}>
                    <p style={{lineHeight: '28px'}}><i className="fa fa-user" style={{fontSize: 20, marginRight: 10}}> </i> Change Password</p>
                  </li>
                </Link>
                <li className={"list-group-item"} style={{height: 50, cursor: 'pointer'}} onClick={()=>this.logout()}>
                  <p style={{lineHeight: '28px'}}><i className="fa fa-sign-out" style={{fontSize: 22, marginRight: 6}}> </i> Logout</p>
                </li>
              </ul>
            </div>
          ):null}

          {(this.state.showDoctorsList)? (
            <div style={{position: 'absolute', width: 220, right: 15, top: 50}}>
              <ul className={"list-group"}>
                {this.state.doctors.map((doctor)=> (
                  (!this.state.members.some(member => member.identity === doctor._id.toString()))? (
                    <li className={"list-group-item"} style={{height: 50, cursor: 'pointer'}} onClick={()=>this.addMember(doctor._id.toString())}>
                      <p style={{lineHeight: '28px'}} className={"text-capitalize"}><i className="fa fa-user-md" style={{fontSize: 20, marginRight: 10}}> </i> {doctor.name}</p>
                    </li>
                  ):null
                ))}
              </ul>
            </div>
          ):null}
        </div>

        {(this.state.showProfile)? (
            <div className="modal d-block" tabIndex="-1" role="dialog" style={{background: 'rgb(0 0 0 / 50%)', color: '#000', paddingTop: 60}}>
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">{this.state.patientName} Profile</h5>
                    <button type="button" className="close" onClick={()=>this.setState({showProfile: false})}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-3 col-md-2">
                        <p><span style={{fontWeight: 'bold'}}>DOB:</span></p>
                      </div>
                      <div className="col-9 col-md-10">
                        <p>{moment(this.state.dob).format('ll')}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3 col-md-2">
                        <p><span style={{fontWeight: 'bold'}}>Gender:</span></p>
                      </div>
                      <div className="col-9 col-md-10">
                        <p className={'text-capitalize'}>{this.state.gender}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3 col-md-2">
                        <p><span style={{fontWeight: 'bold'}}>Email:</span></p>
                      </div>
                      <div className="col-9 col-md-10">
                        <p style={{wordBreak: 'break-all'}}>{this.state.email}</p>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-danger" onClick={()=>this.setState({showProfile: false})}>Close</button>
                  </div>
                </div>
              </div>
            </div>
        ):null}
      </React.Fragment>
    );
  }
}

export default ContactProfile;
