import React, {Component} from 'react';
import Avatar from "../../../assets/img/avatar.jpg";
import {IMAGE_BASE_URL} from "../../../utils/Url";
import $ from 'jquery';
import {Link} from "react-router-dom";

class Profile extends Component {
  constructor(props) {
    super(props);

  }

  closeSidebar = () => {
    if($(window).width() <= 735) {
      $('#sidepanel').hide()
      $('.content').show()
      $('.open-sidebar').show()
    }
  }

  render() {
    return (
      <React.Fragment>
        <div id="profile">
          <div className="wrap">
            <a target={"_blank"} href={(JSON.parse(localStorage.getItem('user')).profile_picture)? (IMAGE_BASE_URL+"/profilePictures/"+JSON.parse(localStorage.getItem('user')).profile_picture):("")}>
              <img id="profile-img" style={{width: 60, height: 60}} src={(JSON.parse(localStorage.getItem('user')).profile_picture)? (IMAGE_BASE_URL+"/profilePictures/"+JSON.parse(localStorage.getItem('user')).profile_picture):(Avatar)} className="online" alt=""/>
            </a>
            <p className={"text-capitalize"}>{JSON.parse(localStorage.getItem('user')).name}</p>
            <Link to={"/profile-setting"} style={{ color: 'white', padding: 10 }}>
              <i className="fa fa-edit"> </i>
            </Link>
          </div>
          <i className="fa fa-chevron-left close-sidebar" onClick={()=>this.closeSidebar()}> </i>
        </div>
      </React.Fragment>
    );
  }
}

export default Profile;
