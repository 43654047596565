import React, {Component} from 'react';
import {TwilioService} from "../../../utils/TwilioService";

class WriteMessage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: '',
      chatClientChannel: this.props.chatClientChannel
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.chatClientChannel !== this.props.chatClientChannel) {
      this.setState({
        chatClientChannel: this.props.chatClientChannel
      });
    }
  }

  onSend = (keyCode, e) => {
    if(keyCode === 13) {
      if(e !== null && e.shiftKey) {
        e.target.style.height = e.target.offsetHeight+20+"px"
      } else {
        if (this.state.message.length > 0 && (/\d/.test(this.state.message) || /[a-zA-Z]/.test(this.state.message))) {
          const attributes = {author: JSON.parse(localStorage.getItem('user')).name, _id: JSON.parse(localStorage.getItem('user'))._id, created_at: Date.now()};
          this.state.chatClientChannel?.sendMessage(this.state.message, attributes);
          this.setState({message: ''})
          document.getElementById('messageInput').style.height = "48px"
        }
      }
    }
  }

  sendMedia = (e) => {
    const files = Array.from(e.target.files);
    files.forEach(file=> {
      const formData = new FormData();
      formData.append('file', file);
      TwilioService.getInstance()
        .getChatClient()
        .then((client) => client.getChannelBySid(this.state.chatClientChannel.sid).then(function(channel) {
          const attributes = {author: JSON.parse(localStorage.getItem('user')).name, _id: JSON.parse(localStorage.getItem('user'))._id, created_at: Date.now()};
          channel.sendMessage(formData, attributes);
        }))
    })
  }

  render() {
    return (
      <React.Fragment>
        <div className="message-input">
          <div className="wrap">
            <textarea rows={1} id={'messageInput'} style={{maxHeight: 180}} placeholder="Write your message..." onKeyUp={(e)=>this.onSend(e.keyCode, e)} value={this.state.message} onChange={(e)=>this.setState({message: e.target.value})}> </textarea>
            <label htmlFor="myFile" style={{marginBottom: 0}}><i className="fa fa-paperclip attachment" aria-hidden="true"> </i></label>
            <input style={{display: "none"}} type="file" multiple id="myFile" onChange={(e)=>this.sendMedia(e)} name="myFile"/>
            <button className="submit" onClick={()=>this.onSend(13, null)}><i className="fa fa-paper-plane" aria-hidden="true"> </i></button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default WriteMessage;
