import React, {Component} from 'react';
import loader from '../assets/img/loader.gif';

class Loader extends Component {
  render() {
    return (
      <React.Fragment>
        <div id="loader">
          <div style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: 'rgba(255,255,255,0.3)', zIndex: 9999999}}> </div>
          <div style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, zIndex: 99999999, margin: 'auto', width: 90, height: 90 }}>
            <img src={loader} alt="not found" style={{width: '100%'}}/>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Loader;
