import React, {Component} from 'react';
import moment from 'moment';

class ReceivedMessage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: this.props.message,
      mediaUrl: ""
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.message !== this.props.message) {
      this.setState({
        message: this.props.message
      });
    }
  }

  componentDidMount() {
    if(this.props.message.media) {
      this.props.message.media.getContentTemporaryUrl().then((url) => {
        this.setState({
          mediaUrl: url
        })
      })
    }
  }

  render() {
    return (
      <React.Fragment>
        <li className="replies message" onDoubleClick={this.props.onDoubleClick}>
          {(this.state.message.media)? (
            <React.Fragment>
              {(this.state.message.media.contentType === "image/png" || this.state.message.media.contentType === "image/jpeg")? (
                <a href={this.state.mediaUrl} target={"_blank"} style={{paddingLeft: 0, paddingTop: 0, float: 'right'}}><img src={this.state.mediaUrl} style={{ marginTop: 0, width: 220, borderRadius: 5, height: 170 }} alt="not found"/></a>
              ):(
                <a href={this.state.mediaUrl} target={"_blank"} style={{textDecoration: 'none', color: 'black', background: '#cecece', padding: "10px 15px", borderRadius: 20, float: 'right'}}>
                  <div className="row">
                    <div className="col-md-2" style={{margin: 'auto'}}>
                      <div>
                        {(this.state.message.media.contentType === "application/pdf")? (
                          <i className={"fa fa-file-pdf-o"} style={{paddingLeft: 7, fontSize: 26, color: 'red'}}> </i>
                        ):null}
                        {(this.state.message.media.contentType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document")? (
                          <i className={"fa fa-file-word-o"} style={{paddingLeft: 7, fontSize: 26, color: "#0055b1"}}> </i>
                        ):null}
                      </div>
                    </div>
                    <div className="col-md-10" style={{margin: 'auto'}}>
                      <div>{this.state.message.media.filename}</div>
                    </div>
                  </div>
                </a>
              )}
            </React.Fragment>
          ):(
            <p>{this.state.message.text}</p>
          )}
          <p className={"text-capitalize"} style={{background: 'none', color: '#878787', float: 'right', clear: 'both', fontSize: 11, padding: 0, display: 'block', marginLeft: 5}}>{this.state.message.user.author}</p>
          <p className={"text-capitalize"} style={{background: 'none', color: '#878787', float: 'right', clear: 'both', fontSize: 11, padding: 0, display: 'block', marginLeft: 5}}>{moment(this.state.message.created_at).format('lll')}</p>
        </li>
      </React.Fragment>
    );
  }
}

export default ReceivedMessage;
